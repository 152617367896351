


































import accountStateRef from "@/apps/accounts/modules/store";
import { computed, defineComponent } from "@vue/composition-api";
import { truncate } from "@/apps/core/modules/utils/text";

export default defineComponent({
  name: "KomponenAktivitasTag",
  props: {
    namedUrl: { type: String, required: true },
    aktKomp: { type: Object, required: true },
    keterampilan: { type: Boolean, default: false },
  },
  setup(props) {
    const iconMap: Record<string, string> = {
      Ya: "check",
      Tidak: "times",
    };

    const kompetensiMap: Record<string, string> = {
      "Tahap I": "is-observasi",
      "Tahap II": "is-supervisi",
      "Tahap III": "is-mandiri",
    };
    const aktKomp = props.aktKomp;
    const tagType = computed(() =>
      aktKomp.verifikasi === "Tidak" ? "is-danger" : "is-success"
    );
    const isVerified = computed(
      () => aktKomp.verifikasi === "Ya" // Jangan dihardcode, perlu simpan di APP_CONFIG
    );

    const konsulenId = accountStateRef.me.anggota?.konsulen?.id ?? "";
    const residenId = accountStateRef.me.anggota?.residen?.id ?? "";

    const kompetensiClass = computed(() => {
      const kompetensiClass = kompetensiMap[aktKomp.kompetensi];
      if (aktKomp.utama) {
        return "is-danger";
      } else if (kompetensiClass) {
        return kompetensiClass;
      } else if (
        aktKomp.aktivitasresidenResidenId == residenId ||
        aktKomp.aktivitasresidenDpjpId == konsulenId
      ) {
        return "is-success";
      }
      return "is-info";
    });

    const getIcon = (verifikasi: string) => {
      return iconMap[verifikasi];
    };

    return {
      // Computed
      isVerified,
      kompetensiClass,
      tagType,

      // Method
      truncate,
      getIcon,
    };
  },
});
